import React, { useEffect, useRef, useState } from 'react';
import QRCodeStyling from 'qr-code-styling';
import DownloadIcon from "../../../assets/icons/ArrowLineDown.png";
import PropTypes from 'prop-types';

function QRCode({
  url,
  mainColor,
  bgColor,
  logoImage,
  selectedOverallStyle,
  selectedEyeFrameStyle,
  selectedEyeBallStyle,
  customCornerDotPath,
}) {
  const qrCodeRef = useRef(null);
  const qrCode = useRef(null);
  const [activeButton, setActiveButton] = useState('PNG');
  const [activeQuality, setQualityButton] = useState('1x');

  useEffect(() => {
    qrCode.current = new QRCodeStyling({
      width: 280 * (activeQuality === '1x' ? 1 : 2),
      height: 280 * (activeQuality === '1x' ? 1 : 2),
      data: url || 'https://qr-quick.site/',
      image: logoImage ? URL.createObjectURL(logoImage) : undefined,
      imageOptions: {
        crossOrigin: 'anonymous',
        margin: 5, 
        height: 50, 
        width: 50,  
      },
      dotsOptions: {
        color: mainColor || '#000',
        type: selectedOverallStyle || 'square',
      },
      cornersSquareOptions: {
        color: mainColor || '#000',
        type: selectedEyeFrameStyle || 'square',
      },
      cornersDotOptions: {
        color: mainColor || '#000',
        type: selectedEyeBallStyle || 'square',
        customCornersDot:
          selectedEyeBallStyle === 'custom'
            ? customCornerDotPath
            : undefined,
      },
      backgroundOptions: {
        color: bgColor || '#fff',
      },
      qrOptions: {
        errorCorrectionLevel: 'H', // Increased from 'Q' to 'H' for better error correction
      },
    });

    qrCode.current.append(qrCodeRef.current);
  }, [activeQuality]); // Initialize only once or when quality changes

  useEffect(() => {
    if (qrCode.current) {
      qrCode.current.update({
        width: 250 * (activeQuality === '1x' ? 1 : 2),
        height: 250 * (activeQuality === '1x' ? 1 : 2),
        data: url || 'https://qr-quick.site/',
        image: logoImage ? URL.createObjectURL(logoImage) : undefined,
        imageOptions: {
          crossOrigin: 'anonymous',
          margin: 5,
          height: 50,
          width: 50,
        },
        dotsOptions: {
          color: mainColor || '#000',
          type: selectedOverallStyle || 'square',
        },
        cornersSquareOptions: {
          color: mainColor || '#000',
          type: selectedEyeFrameStyle || 'square',
        },
        cornersDotOptions: {
          color: mainColor || '#000',
          type: selectedEyeBallStyle || 'square',
          customCornersDot: selectedEyeBallStyle === 'custom' ? customCornerDotPath : undefined,
        },
        backgroundOptions: {
          color: bgColor || '#fff',
        },
        qrOptions: {
          errorCorrectionLevel: 'H',
        },
      });
    }
  }, [
    url,
    mainColor,
    bgColor,
    logoImage,
    selectedOverallStyle,
    selectedEyeFrameStyle,
    selectedEyeBallStyle,
    customCornerDotPath,
    activeQuality,
  ]);

  const handleDownload = () => {
    qrCode.current.download({
      name: 'qr-code',
      extension: activeButton.toLowerCase(),
    });
  };

  const isDownloadDisabled = !url;

  return (
    <div className="col-start-3 p-5 bg-white flex mx-auto lg:mt-8 mt-2 rounded-lg flex-col w-full row-start-1 row-end-4 mb-auto">
      <div className='w-min mx-auto flex flex-col box'>
        <div ref={qrCodeRef} className='w-auto p-2 h-auto mx-auto border-4 rounded-lg border-navLangOptionsPurple' />
        <div className="flex w-full h-8 md:max-w-xl m-4 mx-auto box-border rounded shadow border-4 border-navLangOptionsPurple">
          <button
            aria-current={activeButton === 'JPEG'}
            onClick={() => setActiveButton('JPEG')}
            className={`w-full flex items-center justify-center font-manrope rounded-r ${
              activeButton === 'JPEG' ? 'bg-activeButtonPurple text-white' : 'bg-white text-gray-800'
            } lg:hover:bg-gray-100 active:scale-95`}
          >
            JPEG
          </button>
          <button
            aria-current={activeButton === 'PNG'}
            onClick={() => setActiveButton('PNG')}
            className={`w-full flex items-center justify-center font-manrope rounded-r ${
              activeButton === 'PNG' ? 'bg-activeButtonPurple text-white' : 'bg-white text-gray-800'
            } border-gray-200 lg:hover:bg-gray-100 active:scale-95`}
          >
            PNG
          </button>
          <button
            aria-current={activeButton === 'SVG'}
            onClick={() => setActiveButton('SVG')}
            className={`w-full flex justify-center items-center font-manrope rounded-r ${
              activeButton === 'SVG' ? 'bg-activeButtonPurple text-white' : 'bg-white text-gray-800'
            } border-gray-200 lg:hover:bg-gray-100 active:scale-95`}
          >
            SVG
          </button>
        </div>
        <div className="lg:flex hidden w-full h-8 items-center md:max-w-xl m-3 mt-1 mb-5 mx-auto rounded-md shadow border-4 border-navLangOptionsPurple">
          <button
            aria-current={activeQuality === '1x'}
            onClick={() => setQualityButton('1x')}
            className={`w-full h-full flex items-center justify-center font-manrope rounded-r px-5 py-2 border ${
              activeQuality === '1x' ? 'bg-activeButtonPurple text-white' : 'bg-white text-gray-800'
            } border-gray-200 lg:hover:bg-gray-100 active:scale-95`}
          >
            1x
          </button>
          <button
            aria-current={activeQuality === '2x'}
            onClick={() => setQualityButton('2x')}
            className={`w-full h-full flex items-center gap-x-2 justify-center font-manrope rounded-r px-5 py-2 border ${
              activeQuality === '2x' ? 'bg-activeButtonPurple text-white' : 'bg-white text-gray-800'
            } border-gray-200 lg:hover:bg-gray-100 active:scale-95`}
          >
            2x
          </button>
        </div>
        <button
          onClick={handleDownload}
          disabled={isDownloadDisabled}
          className={`w-full h-10 font-manrope flex items-center justify-center rounded-2xl ${
            isDownloadDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-radial-gradient text-white'
          }`}
        >
          Download
          <img src={DownloadIcon} alt='Download Icon' className='h-5 w-auto pl-2' />
        </button>
      </div>
    </div>
  );
}

QRCode.propTypes = {
  url: PropTypes.string,
  mainColor: PropTypes.string,
  bgColor: PropTypes.string,
  logoImage: PropTypes.instanceOf(File),
  selectedOverallStyle: PropTypes.string,
  selectedEyeFrameStyle: PropTypes.string,
  selectedEyeBallStyle: PropTypes.string,
  customCornerDotPath: PropTypes.string,
};

export default QRCode;
