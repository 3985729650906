import React, { useEffect } from 'react';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-buy-button': {
        "buy-button-id": string|undefined;
        "publishable-key": string|undefined;
         style?: React.CSSProperties;
      };
    }
  }
}

const PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const BUY_BUTTON_ID = process.env.REACT_APP_STRIPE_BUY_BUTTON_ID;

const StripeButton: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/buy-button.js';
    script.async = true;

    script.onload = () => {
      const handleMessage = (event: MessageEvent) => {
        try {
          if (event.data && typeof event.data.payload !== 'undefined') {
          } else {
            return;
          }
        } catch (error) {
          console.error('Error handling Stripe event:', error);
        }
      };

      window.addEventListener('message', handleMessage);

      return () => {
        window.removeEventListener('message', handleMessage);
      };
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className='lg:scale-125 lg:p-8'>
      <stripe-buy-button
        buy-button-id={BUY_BUTTON_ID}
        publishable-key={PUBLISHABLE_KEY} 
      />
    </div>
  );
};

export default StripeButton;

