import React from 'react';
import LinkIcon from '../../../assets/icons/LinkBreak.png';

function UrlInput({ url, setUrl }) {
  return (
    <div className="p-6 flex bg-white flex-col 	justify-items: center items-start font-manrope rounded-lg max-w-3xl col-span-2 mb-auto ">
      <label htmlFor="url" className='pb-3 text-boldTextGray font-semibold'>Enter URL:</label>
      <div className='flex rounded-md rounded-lg border-none bg-navLangOptionsPurple items-center w-full'>
        <img src={LinkIcon} alt="link-icons" className='w-auto h-6 pl-3' />
        <input
            type="text"
            id="url"
            placeholder="https://qr-quick.site/"
            className="bg-transparent w-full p-2"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            required
        />
      </div>
    </div>
  );
}

export default UrlInput;
