import React, { useState } from "react";
import ColorPicker from '../ColorPicker/ColorPicker';
import SwatchIcon from "../../../assets/icons/Swatches.png";
import ColorPalletteIcon from "../../../assets/icons/Eyedropper.png";
import EyeDropperIcon from "../../../assets/icons/EyeBall.png";
import EyeFrameIcon from "../../../assets/icons/EyeFrame.png";
import ScreenshotUploader from '../../../common/ScreenshotUploader/ScreenshotUploader'; 
import { useTranslation } from "react-i18next";

function DesignPanel({
  mainColor,
  setMainColor,
  bgColor,
  setBgColor,
  logoFile,
  setLogoFile,
  selectedOverallStyle,
  setSelectedOverallStyle,
  selectedEyeFrameStyle,
  setSelectedEyeFrameStyle,
  selectedEyeBallStyle,
  setSelectedEyeBallStyle,
}) {
  const { t } = useTranslation();

  const [activePicker, setActivePicker] = useState(null);

  const handleButtonClick = (picker) => {
    setActivePicker(picker);
  };

  const handleColorChange = (newColor) => {
    if (activePicker === 'main') {
      setMainColor(newColor); 
    } else if (activePicker === 'background') {
      setBgColor(newColor); 
    }
    setActivePicker(null); 
  };

  const getIconColor = (icon, selectedIcon) => {
    return icon === selectedIcon ? "#958AF0" : "#A5A6B5"; 
  };

  return (
    <div className="bg-white w-full h-auto p-3 col-span-2 row-span-3 mb-auto rounded-lg items-start grid box-border">
      <div className="flex flex-col items-start w-auto p-3 gap-y-10">
        <div className="items-start flex font-manrope text-boldTextGray font-semibold">
          {t('Choose your design')}
        </div>
        {/* Colors Section */}
        <div className="w-full">
          <div className="font-manrope font-semibold text-boldTextGray items-center flex flex-row-reverse justify-end">
            {t('Colors')}
            <img src={ColorPalletteIcon} alt="color palette icon" className="h-5 w-auto pr-2" />
          </div>
          <div className="flex w-auto lg:items-center items-start justify-start flex-col lg:flex-row pt-3">
            {/* Main Color Picker */}
            <div className="flex items-center border border-2 border-gray-300 rounded-xl w-auto p-2">
              <div className="font-manrope font-semibold text-boldTextGray">
                {t('Main')}
                <div className="font-manrope text-navButtonsGray">{mainColor}</div>
              </div>
              <button
                className="mx-3 p-4 rounded border border-2 border-gray-300"
                style={{ backgroundColor: mainColor, boxShadow: '0 0 0 2px #E3E4FC inset' }}
                onClick={() => handleButtonClick('main')}
              ></button>
            </div>
            {/* Background Color Picker */}
            <div className="flex items-center w-auto border border-2 max-w-38 border-gray-300 rounded-xl lg:ml-3 lg:mt-0 mt-3 p-2">
              <div className="font-manrope font-semibold text-boldTextGray">
                {t('Background')}
                <div className="font-manrope text-navButtonsGray">{bgColor}</div>
              </div>
              <button
                className="mx-3 p-4 rounded border border-2 border-gray-300"
                style={{ backgroundColor: bgColor, boxShadow: '0 0 0 2px #E3E4FC inset' }}
                onClick={() => handleButtonClick('background')}
              ></button>
            </div>
            {/* Color Picker Component */}
            <div className="flex w-auto ml-8">
              {activePicker && <ColorPicker onColorChange={handleColorChange} />}
            </div>
          </div>
        </div>
        {/* Overall Style Section */}
        <div>
          <div className="font-manrope font-semibold text-boldTextGray items-center flex flex-row-reverse justify-end">
            {t('Overall Style')}
            <img src={SwatchIcon} alt="swatch icon" className="h-5 w-auto pr-2" />
          </div>
          <div className="flex flex-row gap-3 pt-3">
            {/* Overall Style Option 1 */}
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none"
              onClick={() => setSelectedOverallStyle("square")} style={{ cursor: "pointer" }}>
              <rect x="0.75" y="0.75" width="62.5" height="62.5" rx="7.25" fill="white" />
              <rect x="0.75" y="0.75" width="62.5" height="62.5" rx="7.25" stroke={getIconColor("square", selectedOverallStyle)} strokeWidth="1.5" />
              <rect x="16" y="16" width="10" height="10" fill={getIconColor("square", selectedOverallStyle)} />
              <rect x="27" y="16" width="10" height="10" fill={getIconColor("square", selectedOverallStyle)} />
              <rect x="38" y="16" width="10" height="10" fill={getIconColor("square", selectedOverallStyle)} />
              <rect x="16" y="27" width="10" height="10" fill={getIconColor("square", selectedOverallStyle)} />
              <rect x="16" y="38" width="10" height="10" fill={getIconColor("square", selectedOverallStyle)} />
              <rect x="38" y="38" width="10" height="10" fill={getIconColor("square", selectedOverallStyle)} />
            </svg>
            {/* Overall Style Option 2 */}
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none"
              onClick={() => setSelectedOverallStyle("dots")} style={{ cursor: "pointer" }}>
              <rect x="0.75" y="0.75" width="62.5" height="62.5" rx="7.25" stroke={getIconColor("dots", selectedOverallStyle)} strokeWidth="1.5" />
              <rect x="16" y="16" width="10" height="10" rx="5" fill={getIconColor("dots", selectedOverallStyle)} />
              <rect x="27" y="16" width="10" height="10" rx="5" fill={getIconColor("dots", selectedOverallStyle)} />
              <rect x="38" y="16" width="10" height="10" rx="5" fill={getIconColor("dots", selectedOverallStyle)} />
              <rect x="16" y="27" width="10" height="10" rx="5" fill={getIconColor("dots", selectedOverallStyle)} />
              <rect x="16" y="38" width="10" height="10" rx="5" fill={getIconColor("dots", selectedOverallStyle)} />
              <rect x="38" y="38" width="10" height="10" rx="5" fill={getIconColor("dots", selectedOverallStyle)} />
            </svg>
            {/* Add more overall style options if you have more */}
          </div>
        </div>
        {/* Eye Frame Style Section */}
        <div>
          <div className="font-manrope font-semibold text-boldTextGray items-center flex flex-row-reverse justify-end">
            {t('Eye Frame Style')}
            <img src={EyeFrameIcon} alt="Eye frame icon" className="h-5 w-auto pr-2" />
          </div>
          <div className="flex flex-row gap-3 pt-3 flex-wrap">
            {/* Eye Frame Style Option 1 */}
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none"
              onClick={() => setSelectedEyeFrameStyle("square")} style={{ cursor: "pointer" }}>
              <g clipPath="url(#clip0_109_1392)">
                <rect width="64" height="64" rx="8" fill="white" />
                <rect x="20" y="20" width="55" height="55" stroke={getIconColor("square", selectedEyeFrameStyle)} strokeWidth="8" />
              </g>
              <rect x="0.75" y="0.75" width="62.5" height="62.5" rx="7.25" stroke={getIconColor("square", selectedEyeFrameStyle)} strokeWidth="1.5" />
              <defs>
                <clipPath id="clip0_109_1392">
                  <rect width="64" height="64" rx="8" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {/* Eye Frame Style Option 2 */}
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none"
              onClick={() => setSelectedEyeFrameStyle("extra-rounded")} style={{ cursor: "pointer" }}>
              <g clipPath="url(#clip0_109_1394)">
                <rect width="64" height="64" rx="8" fill="white" />
                <rect x="20" y="20" width="55" height="55" rx="12" stroke={getIconColor("extra-rounded", selectedEyeFrameStyle)} strokeWidth="8" strokeLinejoin="round" />
              </g>
              <rect x="0.75" y="0.75" width="62.5" height="62.5" rx="7.25" stroke={getIconColor("extra-rounded", selectedEyeFrameStyle)} strokeWidth="1.5" />
              <defs>
                <clipPath id="clip0_109_1394">
                  <rect width="64" height="64" rx="8" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="64" 
              height="64" 
              viewBox="0 0 64 64" 
              fill="none"
              onClick={() => setSelectedEyeFrameStyle("dot")} // Change this to the appropriate state handler
              style={{ cursor: "pointer" }} // Make the icon clickable
            >
              <g clipPath="url(#clip0_109_1396)">
                <path 
                  d="M0 8C0 3.58172 3.58172 0 8 0H56C60.4183 0 64 3.58172 64 8V56C64 60.4183 60.4183 64 56 64H8C3.58172 64 0 60.4183 0 56V8Z" 
                  fill="white"
                />
                <rect 
                  x="20" 
                  y="20" 
                  width="55" 
                  height="55" 
                  rx="27.5" 
                  stroke={getIconColor("dot", selectedEyeFrameStyle)} // Dynamic stroke color
                  strokeWidth="8" 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                />
              </g>
              <path 
                d="M0.75 8C0.75 3.99594 3.99594 0.75 8 0.75H56C60.0041 0.75 63.25 3.99594 63.25 8V56C63.25 60.0041 60.0041 63.25 56 63.25H8C3.99594 63.25 0.75 60.0041 0.75 56V8Z" 
                stroke={getIconColor("dot", selectedEyeFrameStyle)} // Dynamic outer stroke color
                strokeWidth="1.5"
              />
              <defs>
                <clipPath id="clip0_109_1396">
                  <path d="M0 8C0 3.58172 3.58172 0 8 0H56C60.4183 0 64 3.58172 64 8V56C64 60.4183 60.4183 64 56 64H8C3.58172 64 0 60.4183 0 56V8Z" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            {/* Add other eye frame style options (dot, frame4, frame5) similarly */}
          </div>
        </div>
        {/* Eye Ball Style Section */}
        <div>
          <div className="font-manrope font-semibold text-boldTextGray items-center flex flex-row-reverse justify-end">
            {t('Eye Ball Style')}
            <img src={EyeDropperIcon} alt="Eye" className="h-5 w-auto pr-2" />
          </div>
          <div className="flex flex-row gap-3 pt-3 flex-wrap">
            {/* Eye Ball Style Option 1 */}
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none"
              onClick={() => setSelectedEyeBallStyle("square")} style={{ cursor: "pointer" }}>
              <g clipPath="url(#clip0_109_1413)">
                <rect width="64" height="64" rx="8" fill="white" />
                <rect x="16" y="16" width="63" height="63" fill={getIconColor("square", selectedEyeBallStyle)} />
              </g>
              <rect x="0.75" y="0.75" width="62.5" height="62.5" rx="7.25" stroke={getIconColor("square", selectedEyeBallStyle)} strokeWidth="1.5" />
              <defs>
                <clipPath id="clip0_109_1413">
                  <rect width="64" height="64" rx="8" fill="white" />
                </clipPath>
              </defs>
            </svg>
             {/* Eye Ball Style Option 2 */}
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none"
              onClick={() => setSelectedEyeBallStyle("none")} style={{ cursor: "pointer" }}>
              <g clipPath="url(#clip0_109_1415)">
                <rect width="64" height="64" rx="8" fill="white" />
                <rect x="16" y="16" width="63" height="63" rx="16" fill={getIconColor("none", selectedEyeBallStyle)} />
              </g>
              <rect x="0.75" y="0.75" width="62.5" height="62.5" rx="7.25" stroke={getIconColor("none", selectedEyeBallStyle)} strokeWidth="1.5" />
              <defs>
                <clipPath id="clip0_109_1415">
                  <rect width="64" height="64" rx="8" fill="white" />
                </clipPath>
              </defs>
            </svg> */}
                   {/* Eye Ball Style Option 3 */}
             <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="64" 
                height="64" 
                viewBox="0 0 64 64" 
                fill="none"
                onClick={() => setSelectedEyeBallStyle("dot")} // Change this to match your state handler
                style={{ cursor: "pointer" }} // Pointer to indicate it's clickable
              >
                <g clipPath="url(#clip0_109_1417)">
                  <path 
                    d="M0 8C0 3.58172 3.58172 0 8 0H56C60.4183 0 64 3.58172 64 8V56C64 60.4183 60.4183 64 56 64H8C3.58172 64 0 60.4183 0 56V8Z" 
                    fill="white" 
                  />
                  <rect 
                    x="16" 
                    y="16" 
                    width="63" 
                    height="63" 
                    rx="31.5" 
                    fill={getIconColor("dot", selectedEyeBallStyle)} // Dynamic fill color
                  />
                </g>
                <path 
                  d="M0.75 8C0.75 3.99594 3.99594 0.75 8 0.75H56C60.0041 0.75 63.25 3.99594 63.25 8V56C63.25 60.0041 60.0041 63.25 56 63.25H8C3.99594 63.25 0.75 60.0041 0.75 56V8Z" 
                  stroke={getIconColor("dot", selectedEyeBallStyle)} // Dynamic outer stroke color
                  strokeWidth="1.5" 
                />
                <defs>
                  <clipPath id="clip0_109_1417">
                    <path 
                      d="M0 8C0 3.58172 3.58172 0 8 0H56C60.4183 0 64 3.58172 64 8V56C64 60.4183 60.4183 64 56 64H8C3.58172 64 0 60.4183 0 56V8Z" 
                      fill="white" 
                    />
                  </clipPath>
                </defs>
              </svg>
            {/* Add other eye ball style options (dot, eye4, eye5) similarly */}
          </div>
        </div>
        {/* Logo Upload Section */}
        <div className="flex flex-col w-full">
          <h2 className="text-boldTextGray font-semibold mb-4 font-manrope text-start lg:text-left">{t('Your logo')}</h2>
          <div className="flex flex-col lg:flex-row w-full gap-x-3">
            <ScreenshotUploader
              onFileSelect={setLogoFile}
              className="lg:w-2/3 w-full"
              classNameChild='h-full flex flex-col items-center justify-center'
              t={t}
            />
            <div className="flex flex-col w-full lg:w-1/3 items-start justify-center">
              <div className="flex items-center mt-4 lg:mt-0">
                <input type="checkbox" id="remove-background" className="mr-2" />
                <label htmlFor="remove-background" className="text-gray-500 text-sm font-manrope">
                  {t('Remove background behind logo')}
                </label>
              </div>
              <p className="text-gray-500 w-auto mt-2 text-xs border border-2 rounded p-2 border-logoLightPurple text-smallTextGray font-manrope">
                {t('Upload your own custom logo image as .png, .jpg, .gif or .svg file format with a maximum size of')}
                <span className="font-bold"> 2 MB</span>. {t('You can also select a logo for your QR code from the gallery.')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DesignPanel;
