// 'use client';
import QRQuickIcon from '../../../assets/icons/QRQuickLogo.png';
import { useState } from 'react';
import clsx from 'clsx'
import {
  Field,
  Select,
  Dialog,
  DialogPanel,
  PopoverGroup,
} from '@headlessui/react';
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { NavLink } from 'react-router-dom';

export default function Main() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className='pt-3'>
        <nav
          aria-label="Global"
          className=" lg:mx-auto  m-3 bg-white height-full flex p-3 lg:w-4/6 items-center justify-between  lg:py-0 lg:px-2 rounded-lg"
        >
            <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <div className="flex lg:flex-1">
          <NavLink to="/" className="flex items-center pl-4 -m-1.5 p-1.5">
          <img
            alt="QR-Quick Logo"
            src={QRQuickIcon}
            className="h-8 w-auto"
          />
          <span   
          className="ml-2 text-2xl  text-gray-900 text-titleBlue font-cherry"
          >QR-Quick</span>
        </NavLink>
      </div>
        <PopoverGroup className="hidden lg:flex lg:gap-x-12">
          <NavLink to="/" 
            className={({ isActive }) =>
              isActive
                ? "py-3 text-lg font-manrope leading-6 font-semibold text-gray-900 text-navButtonsPurple border-b-2 border-navButtonsPurple transition duration-300"
                : "py-3 text-lg text-navButtonsGray font-semibold font-manrope leading-6 text-gray-900 hover:text-navButtonsPurple border-b-2 border-transparent hover:border-navButtonsPurple transition duration-300"
            }
          >
            QR Generator
          </NavLink>
          <NavLink to="/support"
           className={({ isActive }) =>
            isActive
              ? "py-3 text-lg font-manrope leading-6 font-semibold  text-gray-900 text-navButtonsPurple border-b-2 border-navButtonsPurple transition duration-300"
              : "py-3 text-lg text-navButtonsGray font-semibold font-manrope leading-6 text-gray-900 hover:text-navButtonsPurple border-b-2 border-transparent hover:border-navButtonsPurple transition duration-300"
            }
          >
            Support
          </NavLink>
          <NavLink to="/donate"
           className={({ isActive }) =>
            isActive
              ? "py-3 text-lg font-manrope leading-6 font-semibold text-gray-900 text-navButtonsPurple border-b-2 border-navButtonsPurple transition duration-300"
              : "py-3 text-lg text-navButtonsGray font-semibold font-manrope leading-6 text-gray-900 hover:text-navButtonsPurple border-b-2 border-transparent hover:border-navButtonsPurple transition duration-300"
            } 
          >
            Donate
          </NavLink>
        </PopoverGroup>
        <div className=" flex-1 flex justify-end">
          <Field>
            <div className="relative">
              <Select
                className={clsx(
                  'block w-12 appearance-none font-manrope rounded-lg border-none bg-navLangOptionsPurple py-1.5 px-3 text-sm/6 text-navButtonsGray',
                  'focus:outline-navButtonsGray data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-navButttonsGray',
                  '*:text-black'
                )}
              >
                <option value="en">EN</option>
                <option value="sp">SP</option>
                <option value="ru">RU</option>
              </Select>
              <ChevronDownIcon
                className="group pointer-events-none absolute top-2.5 right-0.5 size-4 fill-black/60"
                aria-hidden="true"
              />
            </div>
          </Field>
        </div>
      </nav>
      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-12" />
        <DialogPanel className="fixed inset-y-0 left-0 z-10 w-80 overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 transform transition-transform duration-300"> 
          <div className="flex items-center justify-between">
          <div className="flex lg:flex-1">
          <NavLink to="/" className="flex items-center pl-6 -m-1.5 p-1.5">
          <img
            alt="QR-Quick Logo"
            src={QRQuickIcon}
            className="h-8 w-auto"
          />
          <span   
          className="ml-2 text-2xl  text-gray-900 text-titleBlue font-cherry"
          >QR-Quick</span>
        </NavLink>
      </div>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-4 py-12 flex flex-col align-center">
                  <NavLink to="/" 
                  onClick={() => setMobileMenuOpen(false)} 
                  className={({ isActive }) =>
                    isActive
                      ? "text-purple-500 py-3 text-lg font-manrope font-semibold text-gray-900 text-navButtonsPurple bg-navBarLightPurple rounded-2xl border-navButtonsPurple transition duration-300 flex flex-row items-center justify-center"
                      : "text-gray-500 py-3 text-lg text-navButtonsGray font-semibold font-manrope leading-6 text-gray-900 flex flex-row items-center justify-start"
                  }
                  > 
                    <svg viewBox="0 0 18 18" fill="none" className='w-6 h-auto mr-2'>
                      <path d="M6.75 1.5H2.25C1.83579 1.5 1.5 1.83579 1.5 2.25V6.75C1.5 7.16421 1.83579 7.5 2.25 7.5H6.75C7.16421 7.5 7.5 7.16421 7.5 6.75V2.25C7.5 1.83579 7.16421 1.5 6.75 1.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6.75 10.5H2.25C1.83579 10.5 1.5 10.8358 1.5 11.25V15.75C1.5 16.1642 1.83579 16.5 2.25 16.5H6.75C7.16421 16.5 7.5 16.1642 7.5 15.75V11.25C7.5 10.8358 7.16421 10.5 6.75 10.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M15.75 1.5H11.25C10.8358 1.5 10.5 1.83579 10.5 2.25V6.75C10.5 7.16421 10.8358 7.5 11.25 7.5H15.75C16.1642 7.5 16.5 7.16421 16.5 6.75V2.25C16.5 1.83579 16.1642 1.5 15.75 1.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M10.5 10.5V13.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M10.5 16.5H13.5V10.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M13.5 12H16.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M16.5 15V16.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>QR Generator</span>
                  </NavLink>
                <NavLink to="/support" 
                onClick={() => setMobileMenuOpen(false)} 
                  className={({ isActive }) =>
                    isActive
                      ? "text-purple-500 py-3 text-lg font-manrope font-semibold text-gray-900 text-navButtonsPurple bg-navBarLightPurple rounded-2xl border-navButtonsPurple transition duration-300 flex flex-row items-center justify-center"
                      : "text-gray-500 py-3 text-lg text-navButtonsGray font-semibold font-manrope leading-6 text-gray-900 flex flex-row items-center justify-start "
                  }
                  > 
                 <svg viewBox="0 0 20 20" fill="none" className="w-6 h-auto mr-2">
                  <path d="M10 7.25C10 7.84674 9.76295 8.41903 9.34099 8.84099C8.91903 9.26295 8.34674 9.5 7.75 9.5C7.15326 9.5 6.58097 9.26295 6.15901 8.84099C5.73705 8.41903 5.5 7.84674 5.5 7.25V3.5C5.5 2.90326 5.73705 2.33097 6.15901 1.90901C6.58097 1.48705 7.15326 1.25 7.75 1.25C8.34674 1.25 8.91903 1.48705 9.34099 1.90901C9.76295 2.33097 10 2.90326 10 3.5V7.25Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10 3.5C10 2.90326 10.2371 2.33097 10.659 1.90901C11.081 1.48705 11.6533 1.25 12.25 1.25C12.8467 1.25 13.419 1.48705 13.841 1.90901C14.2629 2.33097 14.5 2.90326 14.5 3.5V5.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10 14C10 13.0054 10.3951 12.0516 11.0983 11.3483C11.8016 10.6451 12.7554 10.25 13.75 10.25H12.25C11.6533 10.25 11.081 10.0129 10.659 9.59099C10.2371 9.16903 10 8.59674 10 8V6.5C10 6.30109 10.079 6.11032 10.2197 5.96967C10.3603 5.82902 10.5511 5.75 10.75 5.75H16.75C17.3467 5.75 17.919 5.98705 18.341 6.40901C18.7629 6.83097 19 7.40326 19 8V9.5C19 11.8869 18.0518 14.1761 16.364 15.864C14.6761 17.5518 12.3869 18.5 10 18.5C7.61305 18.5 5.32387 17.5518 3.63604 15.864C1.94821 14.1761 1 11.8869 1 9.5V5.75C1 5.15326 1.23705 4.58097 1.65901 4.15901C2.08097 3.73705 2.65326 3.5 3.25 3.5C3.84674 3.5 4.41903 3.73705 4.84099 4.15901C5.26295 4.58097 5.5 5.15326 5.5 5.75V7.25C5.5 7.84674 5.26295 8.41903 4.84099 8.84099C4.41903 9.26295 3.84674 9.5 3.25 9.5C2.65326 9.5 2.08097 9.26295 1.65901 8.84099C1.23705 8.41903 1 7.84674 1 7.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                  <span>Support</span>
                  </NavLink>
                  <NavLink to="/donate"
                  onClick={() => setMobileMenuOpen(false)} 
                  className={({ isActive }) =>
                    isActive
                      ? "text-purple-500 py-3 text-lg font-manrope font-semibold text-gray-900 text-navButtonsPurple bg-navBarLightPurple rounded-2xl border-navButtonsPurple transition duration-300 flex flex-row items-center justify-center"
                      : "text-gray-500 py-3 text-lg text-navButtonsGray font-semibold font-manrope leading-6 text-gray-900 flex flex-row items-center justify-start"
                  }
                  > 
                  <svg viewBox="0 0 22 15" fill="none" className="w-6 h-auto mr-2">
                    <path d="M20 0.75H2C1.58579 0.75 1.25 1.08579 1.25 1.5V13.5C1.25 13.9142 1.58579 14.25 2 14.25H20C20.4142 14.25 20.75 13.9142 20.75 13.5V1.5C20.75 1.08579 20.4142 0.75 20 0.75Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M14.749 11.25H17.749" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.249 11.25H11.749" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M1.24902 4.58008H20.749" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <span>Donate</span>
                  </NavLink>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}
